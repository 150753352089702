<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive v-bind:items="rows" v-bind:fields="fields" no-local-sorting
             v-bind:sort-by.sync="sort.field" v-bind:sort-desc.sync="sortIsDesc"
             striped hover @row-clicked="onRowClicked" v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline
        ></b-form-checkbox>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>


      <template #cell(active)="data">
        <span v-if="data.value" style="color:green;">{{ $t('tables.enabled') }}</span>
        <span v-else style="color: darkred">{{ $t('tables.disabled') }}</span>
      </template>
      <template #cell(subject)="data">
        <div v-for="(value, key) in data.value" :key="key">
          <span v-if="value!=null && value!==''">{{ key }}: {{ value }}</span>
        </div>
      </template>
      <template #cell(orgId)="data">
        {{ getOrganizationNameById(data.value) }}
      </template>

      <template #cell(language)="data">
        {{ data.value.join(', ') }}
      </template>


      <template #cell(mailEvent)="data">
        <span style="font-weight: bold"> {{ data.value.replace(/_/g, ' ') }}</span>
      </template>
      <!--      <template #cell(actions)="row">
              <b-button size="sm" class="btn btn-success" @click="onRowClicked(row.item)">
                <i class="fas fa-pencil-alt"></i>
              </b-button>
            </template>-->

    </b-table>
    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.mailTemplates') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';

export default {
  name: 'MailTemplatesTable',
  mixins: [mixinBase, mixinTable, mixinNotifications],

  data: () => ({
    rows: [],
    selected: { all: false },
  }),
  props: {
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 25);
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitById', 'getBusinessUnitNameById', 'getOrganizationNameById']),

    fields() {
      let $this = this;
      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.key}`),
          sortable: true,
          active: column.enable,
        };
      });
      /*   let actionKey = {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          active: true,
        };
        fields.push(actionKey); */
      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true && f.key !== 'body');
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('mailTemplate', ['getAllMailTemplates']),
    async refresh() {
      let $this = this;
      // let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllMailTemplates(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.mailTemplates') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      console.log('refresh()2', result);
      this.rows = result.data;
      this.setMetaDataFromResult(result);
      //console.log('refresh()2 loadingToken', loadingToken);

      //  this.setLoaded(loadingToken);
    }
  }
};
</script>

<style scoped>

</style>
